.list-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.item {
  width: 100%;
  max-width: 300px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.item h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 10px 0;
}

.item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}
.icon {
  font-size: 3rem;
  color: #ff6b6b;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .item {
    flex: 1 1 calc(50% - 20px);
  }
}
@media (min-width: 1024px) {
  .item {
    flex: 1 1 calc(25% - 20px);
  }
}
