/* About.css */

.about {
  padding: 20px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.about-intro,
.about-history,
.about-team,
.about-values {
  margin-bottom: 50px;
}

.about-intro h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #333;
}
.about-intro h2 {
  font-size: 1.5rem;
}

.about-intro p {
  line-height: 1.8;
  font-size: 1rem;
}

.about-history h2,
.about-team h2,
.about-values h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ff6b6b;
}

.about-history p {
  font-size: 1rem;
}

.about-team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.team-member {
  width: 100%;
  max-width: 300px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-member img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 3px solid #ff6b6b;
}

.team-member h3 {
  margin: 10px 0;
  color: #333;
}

.team-member p {
  font-size: 0.9rem;
  color: #666;
}

.about-values {
  text-align: center;
}

.values-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.value-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  text-align: center;
}

.icon {
  font-size: 2rem;
  color: #ff6b6b;
  margin-bottom: 10px;
}

.value-item h3 {
  font-size: 1.5rem;
  color: #333;
}

.value-item p {
  font-size: 1rem;
  color: #666;
}

/* Media Queries */
@media (min-width: 768px) {
  .about {
    padding: 40px 20px;
  }

  .about-intro h1 {
    font-size: 3rem;
  }
  .about-intro h2 {
    font-size: 2rem;
  }
  .about-intro p {
    font-size: 1.5rem;
  }

  .about-history h2,
  .about-team h2,
  .about-values h2 {
    font-size: 2.2rem;
  }

  .team-member {
    flex: 1 1 calc(33.333% - 20px);
  }
}

@media (min-width: 1024px) {
  .about {
    padding: 60px 20px;
  }

  .team-member {
    flex: 1 1 calc(25% - 20px);
  }
}
