/* Header Styles */

.header-container {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-text {
  margin-left: 10px;
}

.firm-name {
  font-size: 1.8rem;
  color: #333;
}

.firm-tagline {
  font-size: 0.9rem;
  color: #ff6b6b;
}

/* Desktop Nav Links */
.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: inherit;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 8px;
  transition: color 0.3s ease, background-color 0.3s ease;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: transparent;
}

.nav-link.active {
  color: #fff;
  background-color: #ff6b6b;
}

.nav-link:hover {
  transform: scale(1.05);
}

.nav-link.pending {
  opacity: 0.7;
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.icon {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: all 0.3s ease;
}

/* Hamburger Menu Animation */
.icon.open {
  transform: rotate(45deg);
  background-color: #ff6b6b;
}

.nav-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  width: 200px;
  z-index: 999;
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .mobile-menu-icon {
    display: flex;
  }
}

@media (min-width: 769px) {
  .mobile-menu-icon {
    display: none;
  }
}
