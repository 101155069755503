.services {
  padding: 20px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.services-intro {
  margin-bottom: 30px;
}

.services-intro h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #333;
}

.services-intro p {
  line-height: 1.8;
  font-size: 1rem;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service-item {
  width: 100%;
  max-width: 300px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 3rem;
  color: #ff6b6b;
  margin-bottom: 10px;
}

.service-item h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 10px 0;
}

.service-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

/* Media Queries */
@media (min-width: 768px) {
  .services {
    padding: 40px 20px;
  }

  .services-intro h1 {
    font-size: 3rem;
  }

  .service-item {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (min-width: 1024px) {
  .services {
    padding: 60px 20px;
  }

  .service-item {
    flex: 1 1 calc(25% - 20px);
  }
}
